* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #fcecc6;
  color: #333;
}

.App-header {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  font-weight: 700;
}

.questions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.question-item {
  width: 40%;
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.question-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.question-item.checked {
  background-color: #006838;  /* Updated background color to #006838 */
  color: white;
}

label {
  font-size: 1.2em;
  color: inherit;
  cursor: pointer; /* Makes the text clickable with pointer cursor */
}

input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
}

button {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
  background-color: #006838;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.4);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #164215;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.5);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
}

p {
  font-size: 1.2em;
  margin-top: 20px;
}

.App-banner {
  width: 100%;     /* Full width */
  height: auto;    /* Maintain aspect ratio */
  margin-bottom: 20px;
  max-height: 400px;  /* Optional: max height to limit banner size */
  object-fit: cover;  /* Ensures the image covers the banner area properly */
}

.powered-by-container {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .powered-by-container {
    margin-top: 100px;
  }
}
